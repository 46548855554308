import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  Chip,
  Stack,
  LinearProgress,
  useMediaQuery,
  Fade,
  Popper,
  TextField,
  Divider,
  CardContent,
  Card,
  CircularProgress,
  Button,
  IconButton,
  Link,
} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import ROIForm from '../components/Roi/RoiForm';
import Logo from '../components/Logo';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import guaranteed from '../Image/guaranteed.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { roi } from '../services/CustomerService';
import AppointmentRequestPopper from '../components/Roi/AppointmentRequestPopper';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
function RoiPage() {
  const theme = useTheme();
  const [pdfUrl, setPdfUrl] = useState(null);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [selectedChip, setSelectedChip] = useState(0);
  const [roiData, setRoiData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [viewROIForm, setViewROIForm] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [customPlantSizeValue, setCustomPlantSizeValue] = useState('');
  const [prjectValue, setProjectValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bookAnchorEl, setBookAnchorEl] = useState(null);
  const [bookPopperOpen, setBookPopperOpen] = useState(false);
  const [reportFormData, setReportFormData] = useState({
    name: '',
    phone: '',
    pin_code: '',
    email: '',
  });
  const [reportSubmitting, setReportSubmitting] = useState(false);
  const [isAppointmentFormOpen, setIsAppointmentFormOpen] = useState(false);
  const [isAppointmentFormSubmitting, setIsAppointmentFormSubmitting] =
    useState(false);
  const [appointmentAnchorEl, setAppointmentAnchorEl] = useState(null);

  const handleClick = (newPlacement) => (event) => {
    if (!formData) {
      alert('Please fill and submit the ROI form first before editing values.');
      return;
    }

    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);

    setCustomPlantSizeValue(formData.custom_plant_size || '');
    setProjectValue(formData.project_value || '');
  };

  const handleGetMyReportClick = (event) => {
    setBookAnchorEl(event.currentTarget);
    setBookPopperOpen(true);
  };
  const handleAppointmentButtonClick = (event) => {
    setAppointmentAnchorEl(event.currentTarget);
    setIsAppointmentFormOpen(true);
    setIsAppointmentFormSubmitting(false);
  };

  const handleBookPopperClose = () => {
    setBookPopperOpen(false);
    setPdfUrl(null);
  };

  const handleAppointmentFormChange = (event) => {
    setReportFormData({
      ...reportFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleAppointmentSubmit = async () => {
    if (
      !reportFormData.name ||
      !reportFormData.phone ||
      !reportFormData.pin_code
    ) {
      alert(
        'Please fill in all required fields (name, phone number, and pin code)',
      );
      return;
    }

    setReportSubmitting(true);

    try {
      const completePayload = {
        property_type: formData.property_type || '',
        state: formData.state || '',
        max_bill: formData.max_bill || '',
        min_bill: formData.min_bill || '',
        load: formData.load || '',
        custom_plant_size: formData.custom_plant_size || '',

        pdf_flag: '1',

        customer_name: reportFormData.name,
        customer_phone: reportFormData.phone,
        pin_code: reportFormData.pin_code,
        email: reportFormData.email,

        investmentAmount: roiData?.annual_bill_profile?.lease?.investment,
        savingsAmount: roiData?.annual_bill_profile?.lease?.savings,
      };

      const response = await roi(completePayload);

      setPdfUrl(response?.access_url?.url);
      alert(
        'Your solar savings report has been generated and sent successfully!',
      );

      setReportFormData({
        name: '',
        phone: '',
        pin_code: '',
        email: '',
      });
    } catch (error) {
      console.error('Error generating report:', error);
      alert('Failed to generate your solar savings report. Please try again.');
    } finally {
      setReportSubmitting(false);
    }
  };

  const submitUpdatedRoiData = async (updatedFormData) => {
    if (isSubmitting) return;

    // setIsSubmitting(true);

    try {
      if (!formData) {
        throw new Error(
          'No initial form data found. Please fill and submit the ROI form first.',
        );
      }

      const completePayload = {
        property_type: formData.property_type || '',
        state: formData.state || '',
        max_bill: formData.max_bill || '',
        min_bill: formData.min_bill || '',
        load: formData.load || '',
        custom_plant_size:
          updatedFormData.custom_plant_size || formData.custom_plant_size || '',
        pdf_flag: '0',
        project_value: updatedFormData.project_value || '',
      };

      const missingFields = [];
      [
        'property_type',
        'state',
        'max_bill',
        'min_bill',
        'load',
        'custom_plant_size',
      ].forEach((field) => {
        if (!completePayload[field]) {
          missingFields.push(field);
        }
      });

      if (missingFields.length > 0) {
        throw new Error(
          `Missing required fields: ${missingFields.join(', ')}. Please complete the ROI form first.`,
        );
      }

      const response = await roi(completePayload);

      setRoiData(response);
      setFormData({
        ...formData,
        ...updatedFormData,
      });
      setViewROIForm(false);
    } catch (error) {
      console.error('Error details:', error);
      alert(
        `${error.message || 'Failed to update ROI data. Please try again.'}`,
      );

      if (error.message && error.message.includes('Missing required fields')) {
        setViewROIForm(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const mobileHeaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    py: 3,
  };

  const defaultHeaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    py: 3,
  };

  const handleChipClick = (index) => {
    setSelectedChip(index);
  };

  const handleRoiSubmit = (data) => {
    setRoiData(data);
    setViewROIForm(false);
  };

  const handlFromData = (data) => {
    setFormData(data);
  };

  const dataToShow = [
    {
      title: 'Annual',
      icon: <CalendarMonthRoundedIcon fontSize='small' />,
    },
    {
      title: 'High bill month',
      icon: <TrendingUpRoundedIcon fontSize='small' />,
    },
    {
      title: 'Moderate billl month',
      icon: <TrendingFlatRoundedIcon fontSize='small' />,
    },
    {
      title: 'Low bill month',
      icon: <TrendingDownRoundedIcon fontSize='small' />,
    },
  ];

  const VisualData = ({ data, roi_Bylease, roi_ByOwn }) => {
    const safeNumber = (value) => value ?? 0;

    const tableData = [
      {
        particular: 'Return on investment',
        doNothing: 0,
        getLease: safeNumber(roi_Bylease),
        ownPlant: safeNumber(roi_ByOwn),
      },
      {
        particular: 'Investment amount today',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.investment),
        ownPlant: safeNumber(data?.own?.investment),
      },
      {
        particular: 'Savings',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.savings),
        ownPlant: safeNumber(data?.own?.savings),
      },
      {},
      {
        particular: (
          <Typography variant='body1' fontWeight={'bold'}>
            Total energy expense
          </Typography>
        ),
        doNothing: (
          <Typography variant='body1' fontWeight={'bold'}>
            {Math.floor(
              (safeNumber(data?.do_nothing.bill_amount) /
                data?.do_nothing.bill_amount) *
                100,
            )}
            %
          </Typography>
        ),
        getLease: (
          <Typography variant='body1' fontWeight={'bold'}>
            {Math.floor(
              (safeNumber(data?.lease.total_energy_expense) /
                data?.do_nothing.bill_amount) *
                100,
            )}
            %
          </Typography>
        ),
        ownPlant: (
          <Typography variant='body1' fontWeight={'bold'}>
            {Math.floor(
              (safeNumber(data?.own.bill_amount) /
                data?.do_nothing.bill_amount) *
                100,
            )}
            %
          </Typography>
        ),
      },
      {
        particular: (
          <Typography variant='body1' fontWeight={'bold'}>
            Energy expense amount
          </Typography>
        ),
        doNothing: (
          <Typography variant='body1' fontWeight={'bold'}>
            {safeNumber(data?.do_nothing.bill_amount)}
          </Typography>
        ),
        getLease: (
          <Typography variant='body1' fontWeight={'bold'}>
            {safeNumber(data?.lease.total_energy_expense)}
          </Typography>
        ),
        ownPlant: (
          <Typography variant='body1' fontWeight={'bold'}>
            {safeNumber(data?.own.bill_amount)}
          </Typography>
        ),
      },
      {
        particular: 'Add: Fixed charges',
        doNothing: safeNumber(data?.do_nothing?.fixed),
        getLease: safeNumber(data?.lease?.fixed),
        ownPlant: safeNumber(data?.own?.fixed),
      },
      {
        particular: 'Add: Variable charges',
        doNothing: safeNumber(data?.do_nothing?.variable),
        getLease: safeNumber(data?.lease?.variable),
        ownPlant: safeNumber(data?.own?.variable),
      },
      {
        particular: 'Add: Solar charges',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.lease_expense),
        ownPlant: 0,
      },
      {
        particular: 'Add: Taxes & surcharges',
        doNothing: safeNumber(data?.do_nothing?.tax),
        getLease: safeNumber(data?.lease?.tax),
        ownPlant: safeNumber(data?.own?.tax),
      },
      {
        particular: 'Less: Generation incentives',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.incentives),
        ownPlant: safeNumber(data?.own?.incentives),
      },
      {},
      {
        particular: 'Units consumed',
        doNothing: safeNumber(data?.do_nothing?.units),
        getLease: safeNumber(data?.lease?.net_units),
        ownPlant: safeNumber(data?.own?.net_units),
      },
      {
        particular: 'Consumption expense per unit',
        doNothing: safeNumber(data?.do_nothing?.unit_rate),
        getLease: safeNumber(data?.lease?.unit_rate),
        ownPlant: safeNumber(data?.own?.unit_rate),
      },
      {
        particular: 'Units produced',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.units_produced),
        ownPlant: safeNumber(data?.own?.units_produced),
      },
      {
        particular: 'Solar plant size',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.plant_size),
        ownPlant: safeNumber(data?.own?.plant_size),
      },
    ];

    const chartData = [
      safeNumber(data?.investment_meter?.solar_lease * 100),
      safeNumber(data?.investment_meter?.solar_ownership * 100),
      safeNumber(data?.investment_meter?.equity * 100),
      safeNumber(data?.investment_meter?.debt * 100),
      safeNumber(data?.investment_meter?.fixed_deposit * 100),
      safeNumber(data?.investment_meter?.savings * 100),
      safeNumber(data?.investment_meter?.do_nothing),
    ];

    const newTableData = {
      1: { value: 10 },
      2: { value: 10 },
      3: { value: 9 },
      4: { value: 9 },
      5: { value: 9 },
      6: { value: 9 },
    };

    const valuesArray = Object.values(newTableData).map((item) => item.value);
    const minVal = Math.min(...valuesArray);
    const maxVal = Math.max(...valuesArray);
    const barColors = valuesArray.map((val) =>
      val === minVal
        ? '#c2c9d6'
        : val === maxVal
          ? theme.palette.secondary.light
          : null,
    );

    const chartLabels = [
      'Lease solar',
      'Buy solar',
      'Equity',
      'Debt',
      'FD',
      'Savings',
      'Do nothing',
    ];

    return (
      <Box gap={2} pb={2}>
        <Box mb={2}>
          <BarChart
            xAxis={[
              {
                scaleType: 'band',
                data: chartLabels,
                colorMap: {
                  type: 'ordinal',
                  colors: barColors,
                },
              },
            ]}
            series={[{ data: chartData }]}
            height={300}
          />
        </Box>
        <TableContainer
          component={Paper}
          sx={{ marginBottom: 4 }}
          pt={4}
          style={{ boxShadow: 'none' }}
        >
          <Table size='small' width='100%'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Particulars</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Do nothing
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Lease solar
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Buy solar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow hover key={row?.particular}>
                  <TableCell fontWeight={'bold'} component='th' scope='row'>
                    {row?.particular}
                  </TableCell>
                  <TableCell align='right'>{row?.doNothing}</TableCell>
                  <TableCell align='right'>{row?.getLease}</TableCell>
                  <TableCell align='right'>{row?.ownPlant}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <>
      <Box
        component='header'
        sx={isMobile ? mobileHeaderStyle : defaultHeaderStyle}
      >
        <Logo />
      </Box>
      <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
        <Box display={viewROIForm ? 'unset' : 'none'}>
          <ROIForm
            onSubmit={handleRoiSubmit}
            formDate={handlFromData}
            initialData={formData}
          />
        </Box>
        {!viewROIForm && roiData && (
          <>
            <Popper
              sx={{ zIndex: 1200 }}
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper elevation={2}>
                    <Card
                      sx={{
                        maxWidth: 350,
                        padding: 2,
                        borderRadius: 3,
                        boxShadow: 3,
                        position: 'relative',
                      }}
                    >
                      <CardContent sx={{ padding: '16px 16px 0px 16px' }}>
                        <TextField
                          id='plant-size-field'
                          label='Plant size'
                          variant='standard'
                          fullWidth
                          value={customPlantSizeValue}
                          onChange={(e) =>
                            setCustomPlantSizeValue(e.target.value)
                          }
                          helperText='Size of rooftop solar plant'
                        />

                        <TextField
                          mt={6}
                          id='budget-field'
                          label='Budget'
                          variant='standard'
                          fullWidth
                          value={prjectValue}
                          onChange={(e) => setProjectValue(e.target.value)}
                          helperText='Maximum budget of the plant'
                        />
                      </CardContent>
                      <Box
                        display={'flex'}
                        direction={'row'}
                        justifyContent={'flex-end'}
                      >
                        <IconButton onClick={() => setOpen(false)}>
                          <CancelIcon />
                        </IconButton>
                        <IconButton
                          disabled={isSubmitting}
                          onClick={() => {
                            const updatedFormData = {
                              custom_plant_size: customPlantSizeValue,
                              project_value: prjectValue,
                            };
                            setOpen(false);
                            submitUpdatedRoiData(updatedFormData);
                          }}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={24} />
                          ) : (
                            <CheckCircleIcon
                              sx={{ color: theme.palette.secondary.light }}
                            />
                          )}
                        </IconButton>
                      </Box>
                    </Card>
                  </Paper>
                </Fade>
              )}
            </Popper>

            <Popper
              sx={{ zIndex: 1200 }}
              open={bookPopperOpen}
              anchorEl={bookAnchorEl}
              placement='bottom-end'
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper elevation={2}>
                    <Card
                      sx={{
                        padding: 4,
                        borderRadius: 3,
                        boxShadow: 3,
                        position: 'relative',
                      }}
                    >
                      <CardContent
                        sx={{
                          paddingRight: '140px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant='h6' mb={2} fontWeight='bold'>
                          Get solar savings report
                        </Typography>
                        <TextField
                          required
                          label='Your name'
                          name='name'
                          value={reportFormData.name}
                          onChange={handleAppointmentFormChange}
                          margin='dense'
                          size='small'
                        />
                        <TextField
                          required
                          label='Phone number'
                          name='phone'
                          value={reportFormData.phone}
                          onChange={handleAppointmentFormChange}
                          margin='dense'
                          size='small'
                        />
                        <TextField
                          required
                          label='Pin code'
                          name='pin_code'
                          value={reportFormData.pin_code}
                          onChange={handleAppointmentFormChange}
                          margin='dense'
                          size='small'
                        />
                        <TextField
                          required
                          label='Email address'
                          name='email'
                          value={reportFormData.email}
                          onChange={handleAppointmentFormChange}
                          margin='dense'
                          size='small'
                        />
                      </CardContent>
                      <Box
                        display={'flex'}
                        direction={'row'}
                        justifyContent={'flex-end'}
                        padding={2}
                        pt={0}
                      >
                        <IconButton onClick={handleBookPopperClose}>
                          <CancelIcon fontSize='small' />
                        </IconButton>
                        {/* <IconButton
                          disabled={reportSubmitting}
                          onClick={handleAppointmentSubmit}
                        >
                          {reportSubmitting ? (
                            <CircularProgress size={24} />
                          ) : (
                            <CheckCircleIcon
                              sx={{ color: theme.palette.secondary.light }}
                            />
                          )}
                        </IconButton> */}
                        <Button
                          onClick={handleAppointmentSubmit}
                          disabled={reportSubmitting}
                          size='small'
                          variant='caption'
                          fontWeight={'bold'}
                          mt={1.5}
                          startIcon={
                            <CheckCircleIcon
                              sx={{ color: theme.palette.secondary.light }}
                            />
                          }
                        >
                          Get report
                        </Button>
                      </Box>
                      {pdfUrl && (
                        <IconButton
                          onClick={() => window.open(pdfUrl, '_blank')}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      )}
                    </Card>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <AppointmentRequestPopper
              open={isAppointmentFormOpen}
              anchorEl={appointmentAnchorEl}
              placement='bottom-end'
              setOpen={setIsAppointmentFormOpen}
              roiFormData={formData}
            />
            <Box pb={6}>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography variant='h5' fontWeight='bold'>
                  Return on investment
                </Typography>
                <Box>
                  <IconButton
                    onClick={() => {
                      setViewROIForm(!viewROIForm);
                    }}
                  >
                    <SettingsIcon
                      sx={{ color: theme.palette.secondary.light }}
                    />
                  </IconButton>
                  <IconButton onClick={handleClick('bottom-end')}>
                    <CurrencyRupeeIcon
                      sx={{ color: theme.palette.secondary.light }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Typography
                variant='body1'
                color={'text.secondary'}
                fontWeight={'bold'}
                gutterBottom
              >
                Flexible plans for all property owners
              </Typography>
              <Box pt={5}>
                <Typography variant='body2' color='text.secondary'>
                  based on {roiData?.annual_bill_profile?.lease?.load}{' '}
                  {formData.property_type.toLowerCase()} load
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  ₹ {formData.min_bill}-{formData.max_bill} in monthly bills in{' '}
                  {formData.state} (
                  <Link
                    href='#'
                    underline='hover'
                    onClick={() => {
                      setViewROIForm(!viewROIForm);
                    }}
                  >
                    change
                  </Link>
                  )
                </Typography>
              </Box>

              <Box width={'fit-content'}>
                <Stack
                  display='flex'
                  flexDirection='row'
                  alignItems={'flex-end'}
                  justifyContent={'space-between'}
                >
                  <Typography
                    variant={isMobile ? 'h5' : 'h4'}
                    fontWeight='600'
                    mt={3}
                  >
                    Invest ₹{' '}
                    {roiData?.annual_bill_profile?.lease?.investment?.toLocaleString() ||
                      '19,000'}{' '}
                    today
                  </Typography>
                  <Box mt={2} display={isMobile ? 'none' : 'unset'}>
                    <img
                      style={{ marginRight: '-65px', marginBottom: '-28px' }}
                      height={131}
                      width={131}
                      src={guaranteed}
                      alt='guaranteed'
                    />
                  </Box>
                </Stack>
                <Typography variant={isMobile ? 'h5' : 'h4'} fontWeight='600'>
                  Save ₹{' '}
                  {roiData?.annual_bill_profile?.lease?.savings?.toLocaleString() ||
                    '9,000'}{' '}
                  every year for next 12 years
                  <Box
                    width={'100%'}
                    display={isMobile ? 'flex' : 'none'}
                    justifyContent={'flex-end'}
                  >
                    <img
                      // style={{ marginRight: '-65px', marginBottom: '-28px' }}
                      height={131}
                      width={131}
                      src={guaranteed}
                      alt='guaranteed'
                    />
                  </Box>
                </Typography>
              </Box>
            </Box>
            <Box
              border={'1px solid #e0e0e0'}
              borderRadius={'9px'}
              p={isMobile ? 1 : 2}
            >
              <Typography
                variant={isMobile ? 'h5' : 'h4'}
                pb={2}
                fontWeight={'bold'}
                mb={2}
                mt={2}
              >
                How this works?
              </Typography>
              <Stack
                direction='row'
                justifyContent={'space-between'}
                mb={2}
                alignItems={'center'}
              >
                <Typography variant='h5' fontWeight={'bold'} mb={2} mt={2}>
                  Estimated electricity expense
                </Typography>
                <Box>
                  <Chip
                    label='Share with friends'
                    sx={{
                      borderRadius: '6em',
                    }}
                    border='1px solid #e0e0e0'
                  />
                  <Chip
                    label='Get my report'
                    sx={{
                      borderRadius: '6em',
                      backgroundColor: theme.palette.primary.lighter,
                      color: 'black',
                      marginLeft: '6px',
                    }}
                    border='1px solid #e0e0e0'
                    onClick={handleGetMyReportClick}
                  />
                </Box>
              </Stack>
              <Stack
                display='flex'
                flexDirection='row'
                justifyContent='flex-start'
                gap={1}
                mb={2}
                height={40}
                sx={{
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                  '&::-webkit-scrollbar': {
                    height: '6px',
                  },
                }}
              >
                {dataToShow.map((data, i) => (
                  <Chip
                    label={data.title}
                    icon={data.icon}
                    key={i}
                    color={selectedChip === i ? 'primary' : 'default'}
                    onClick={() => handleChipClick(i)}
                    sx={{
                      borderRadius: '6em',
                      backgroundColor:
                        selectedChip === i ? 'primary.main' : 'white',
                      border:
                        selectedChip === i
                          ? '1px solid green'
                          : '1px solid gray',
                      color: selectedChip === i ? 'white' : 'gray',
                      '&:hover': {
                        backgroundColor:
                          selectedChip === i ? 'primary.dark' : 'gray.100',
                      },
                    }}
                  />
                ))}
              </Stack>

              {selectedChip === 0 && (
                <VisualData
                  data={roiData?.annual_bill_profile}
                  roi_Bylease={roiData?.annual_bill_profile?.lease?.annual_roi}
                  roi_ByOwn={roiData?.annual_bill_profile?.own?.annual_roi}
                />
              )}
              {selectedChip === 1 && (
                <VisualData
                  data={roiData?.max_bill_profile}
                  roi_Bylease={roiData?.max_bill_profile?.lease?.monthly_roi}
                  roi_ByOwn={roiData?.max_bill_profile?.own?.monthly_roi}
                />
              )}
              {selectedChip === 2 && (
                <VisualData
                  data={roiData?.mid_bill_profile}
                  roi_Bylease={roiData?.mid_bill_profile?.lease?.monthly_roi}
                  roi_ByOwn={roiData?.mid_bill_profile?.own?.monthly_roi}
                />
              )}
              {selectedChip === 3 && (
                <VisualData
                  data={roiData?.min_bill_profile}
                  roi_Bylease={roiData?.min_bill_profile?.lease?.monthly_roi}
                  roi_ByOwn={roiData?.min_bill_profile?.own?.monthly_roi}
                />
              )}
            </Box>
            <Button
              variant='outlined'
              mt={4}
              onClick={handleAppointmentButtonClick}
            >
              Book appointment
            </Button>
          </>
        )}
      </Box>
    </>
  );
}

export default RoiPage;
