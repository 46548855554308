import { useTheme } from '@emotion/react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { truncateText } from '../../utils/utils';
import ImagePreviewModal from './ImagePreviewModel.js';

import imgSrc from '../../Image/error-401.png';

export default function CustomerSitePhotoCard({ initialSitePhotos }) {
  const [sitePhotos, setSitePhotos] = useState([]);
  const [imgArray, setImgArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const [disableLeft, setDisableLeft] = useState(true);
  const [disableRight, setDisableRight] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const plantScroll = useRef(null);

  const scrollLeft = () => {
    if (plantScroll.current) {
      plantScroll.current.scrollLeft -= 250; // Adjust the scroll amount as needed
    }
  };

  // const scrollRight = () => {
  //   if (plantScroll.current) {
  //     plantScroll.current.scrollLeft += 250; // Adjust the scroll amount as needed
  //   }
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (plantScroll.current) {
        const scrollLeft = plantScroll.current.scrollLeft;
        const scrollWidth = plantScroll.current.scrollWidth;
        const clientWidth = plantScroll.current.clientWidth;

        setDisableLeft(scrollLeft <= 0);
        setDisableRight(scrollLeft + clientWidth >= scrollWidth);
        setShowButtons(scrollWidth > clientWidth);
      }
    };

    if (plantScroll.current) {
      plantScroll.current.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check
    }
    return () => {
      if (plantScroll.current) {
        plantScroll.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    // Ensure that initialSitePhotos has the expected structure
    if (initialSitePhotos && initialSitePhotos.siteDetails) {
      setSitePhotos(initialSitePhotos.siteDetails);
      console.log('rohit', initialSitePhotos);
    } else {
      console.warn('initialSitePhotos or siteDetails is undefined');
      setSitePhotos([]); // Reset to an empty array if no valid data
    }
  }, [initialSitePhotos]);

  const handleModalOpen = (images) => {
    const urls = images.map((photo) => photo.url);
    setImgArray(urls);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setImgArray([]);
    setOpenModal(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginBottom: '20px',
      }}
    >
      <Box>
        <ImagePreviewModal
          open={openModal}
          onClose={handleModalClose}
          imageSrcArray={imgArray}
        />
      </Box>

      <Stack>
        <Stack
          direction={'row'}
          display={'flex'}
          alignItems={'center'}
          gap={'10px'}
        >
          <PhotoCameraBackIcon sx={{ color: '#216b38' }} />
          <Typography
            fontSize={theme.typography.h6.fontSize}
            fontWeight={theme.typography.overline.fontWeight}
            color={theme.palette.text.primary}
            mt={2}
            mb={2}
          >
            {initialSitePhotos?.siteDetails?.photos?.length} Installed plant
            photos ({initialSitePhotos?.siteDetails?.length})
          </Typography>
        </Stack>

        {sitePhotos.length === 0 && (
          <Typography>No site photos to show</Typography>
        )}
        {initialSitePhotos?.siteDetails?.length > 0 && (
          <Box display='flex' flexDirection='column' mb={2}>
            <Typography
              fontSize={theme.typography.subtitle1.fontSize}
              fontWeight='500'
            >
              {initialSitePhotos?.siteDetails?.length}&nbsp; sites added
            </Typography>
          </Box>
        )}
        {initialSitePhotos?.siteDetails?.filter(
          (site) => site.verificationStatus === 'VERIFIED',
        ).length > 0 && (
            <Box display='flex' flexDirection='column' mb={2}>
              <Typography
                fontSize={theme.typography.subtitle1.fontSize}
                fontWeight='500'
              >
                {
                  initialSitePhotos?.siteDetails?.filter(
                    (site) => site.verificationStatus === 'VERIFIED',
                  ).length
                }{' '}
                &nbsp; verified with proofs
              </Typography>
            </Box>
          )}

        {/* Scrollable Card Container */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '25px',
            maxHeight: '500px', // Set a fixed height
            // overflowY: 'auto', // Enable vertical scrolling
          }}
        >
          <Stack>
            <Stack
              alignItems='center'
              direction='row'
              maxWidth={{ xs: '95vw', sm: '95vw', md: '90vw', lg: '70vw' }}
              paddingBottom={'4.5rem'}
            >
              <Grid container spacing={2} padding={'1rem'} gap={2}>
                {sitePhotos &&
                  sitePhotos
                    ?.filter((photo) => photo.verificationStatus !== 'REJECTED') // Exclude 'REJECTED'
                    .sort((a, b) => {
                      // Sort by verification status
                      if (
                        a.verificationStatus === 'VERIFIED' &&
                        b.verificationStatus !== 'VERIFIED'
                      ) {
                        return -1;
                      } else if (
                        a.verificationStatus !== 'VERIFIED' &&
                        b.verificationStatus === 'VERIFIED'
                      ) {
                        return 1;
                      } else if (
                        a.verificationStatus === 'PENDING' &&
                        b.verificationStatus !== 'PENDING'
                      ) {
                        return -1;
                      } else if (
                        a.verificationStatus !== 'PENDING' &&
                        b.verificationStatus === 'PENDING'
                      ) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((site) => (
                      <Box
                        key={site.id}
                        sx={{
                          position: 'relative',
                          backdropFilter: 'blur(8px)',
                          borderRadius: '10px',
                          zIndex: 1,
                          marginBottom: '10px',
                        }}
                      >
                        <Card
                          sx={{
                            width: 340,
                            cursor: 'pointer',
                            marginTop: '16px',
                            maxWidth: '500px',
                            overflow: 'hidden',
                            borderRadius: '10px',
                            boxShadow: 'none',
                            border: '1px solid #E0E0E0',
                            '&:hover': {
                              border: 'none'
                            },
                          }}
                        >
                          {site.photos.length > 0 ? (
                            <CardMedia
                              component='img'
                              height='140'
                              image={site.photos[0].url}
                              alt={site.photos[0].name}
                              onClick={() => handleModalOpen(site.photos)}
                            />
                          ) : (
                            <CardMedia
                              component='img'
                              height='140'
                              image={imgSrc}
                              alt='no-site-photo-added'
                            />
                          )}
                          <CardContent
                            sx={{
                              paddingTop: '10px',
                              paddingBottom: '10px !important',
                            }}
                          >
                            <Stack direction={'column'} gap={'0.5rem'}>
                              <Typography
                                variant='body1'
                                fontWeight={'bold'}
                                component={'p'}
                                align={'justify'}
                                display={'flex'}
                                justifyContent={'space-between'}
                              >
                                {truncateText(site.displayName, 40)}, &nbsp;
                                {site.location} 
                                {site.verificationStatus === 'PENDING' ? (
                                  <WatchLaterIcon
                                    fontSize='medium'
                                    color='warning'
                                  />
                                ) : (
                                  <CheckCircleIcon
                                    fontSize='medium'
                                    color='primary'
                                  />
                                )}
                              </Typography>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Box>
                    ))}
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </div>
  );
}
