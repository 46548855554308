import { Box, CircularProgress, Container, Divider, Stack, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import VendorBusinessDocuments from '../components/vendor-details/BusinessDocuments';
import CustomerSitePhotoCard from '../components/vendor-details/CustomerSitePhotoCard';
import VendorProfile from '../components/vendor-details/VendorProfile';
import VendorProfileLayout from '../layoutV2/VendorProfileLayout';
import { getVendorDetails } from '../services/CustomerService';
import { usePageTitleStore } from '../store/PageTitleStore';
import { calculateYears, formatDate } from '../utils/utils';
import VendorProfileStatistics from '../components/vendor-details/VendorProfileStatistics';
import VendorSummary from '../components/vendor-details/VendorSummary';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@emotion/react';


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const VendorProfilePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { vendorInfo } = useParams();
  const [establishmentName, vendorId] = vendorInfo.split('_');
  const { updateTitle, updateDescription, updateImage } = usePageTitleStore();
  const [isLoading, setIsLoading] = useState(false);
  const [vendorData, setVendorData] = useState(null);

  useEffect(() => {
    updateTitle(establishmentName);
    updateImage(vendorData?.logoUrl);
  }, [establishmentName, updateImage, updateTitle, vendorData?.logoUrl]);

  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);
      try {
        const vendorData = await getVendorDetails(btoa(vendorId));
        setVendorData(vendorData);
        const description = `${vendorData.establishmentName} • Estd on ${formatDate(vendorData.establishmentDate)} • ${vendorData.experienceInMW} MW+ exp in ${calculateYears(vendorData.establishmentDate)} years`;
        updateDescription(description);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    onMount();
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const declaredSitesCount = vendorData?.siteDetails?.length;
  const verifiedSitesCount = vendorData?.siteDetails?.filter(
    (site) => site.verificationStatus === 'VERIFIED',
  ).length;
  const rejectedSitesCount = vendorData?.siteDetails?.filter(
    (site) => site.verificationStatus === 'REJECTED',
  ).length;
  const verifiedCountOfPastInstallations =
    vendorData?.documents?.identity.filter(
      (identity) => identity.verificationStatus === 'VERIFIED',
    ).length;
  // const verifiedReviewsCount = reviewCount.filter(
  //   (review) => review.verificationStatus === 'VERIFIED',
  // ).length;
  const StatusCard = ({ value, label, subLabel, verified }) => {
    return (
      <Box>
        {subLabel !== 'reviews' && <>
          <Typography
            fontSize={{ xs: '22px', sm: '32px' }}
            sx={{ fontWeight: 'bold', color: 'green' }}
          >
            {value}{' '}
            {verified && (
              <CheckCircleIcon fontSize='small' sx={{ color: 'green' }} />
            )}
          </Typography>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            {label}
          </Typography>
          <Typography variant='body2' sx={{ color: 'gray' }}>
            {subLabel}
          </Typography>
        </>}
      </Box>
    );
  };
  console.log("vendorData", vendorData);

  const vendorProfilePageContent = () => {
    return isLoading ? (
      <CircularProgress sx={{ alignSelf: 'center' }} />
    ) : (
      <>
        <Container px={2} py={2}>
          <Box mb={20} mt={2} sx={{ padding: { xs: '0px', md: '0px 24px' } }}>
            <VendorProfile vendor={vendorData} />
            <Stack
              pt={2}
              direction='row'
              justifyContent='space-between'
              width={isMobile ? '100%' : '60%'}
            >
              <StatusCard
                value={declaredSitesCount - rejectedSitesCount + verifiedCountOfPastInstallations}
                label='declared'
                subLabel='sites'
              />
              <StatusCard
                value={verifiedSitesCount + verifiedCountOfPastInstallations}
                label='verified'
                subLabel='sites'
                verified
              />
              <StatusCard
                // value={verifiedReviewsCount}
                value={'#'}
                label='verified'
                subLabel='reviews'
                verified
              />
            </Stack>
            <Box sx={{
              pt: 2,
            }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Site Photos" sx={{ textTransform: 'none' }} />
                {/* <Tab label="Reviews" sx={{ textTransform: 'none' }} /> */}
                {/* <Tab label="Service regions" sx={{ textTransform: 'none' }} /> */}
                <Tab label="Documents" sx={{ textTransform: 'none' }} />
                <Tab label="Details" sx={{ textTransform: 'none' }} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <CustomerSitePhotoCard initialSitePhotos={vendorData} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <VendorBusinessDocuments vendor={vendorData} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                {/* <VendorProfileStatistics vendor={vendorData} /> */}
                <VendorSummary vendor={vendorData} />
              </TabPanel>
            </Box>
            {/* <Divider sx={{ p: 1, mb: 2 }} />
        <Divider sx={{ p: 1, mb: 2 }} /> */}
          </Box>
        </Container>
      </>
    );
  };

  return (
    <VendorProfileLayout vendorData={vendorData}>
      {vendorProfilePageContent()}
    </VendorProfileLayout>
  );
};

export default VendorProfilePage;
