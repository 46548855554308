import React, { useState } from 'react';
import {
  Popper,
  Fade,
  Paper,
  Card,
  CardContent,
  TextField,
  Box,
  IconButton,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const DISCORD_WEBHOOK_URL =
  'https://discord.com/api/webhooks/1353612207136444578/721kdqkqJLAQ8A4IncYEFGdOK6EpjFyshPy_8dj5xrVoJbrbfOuHLBBaIIhjyvHALP5J';

const AppointmentRequestPopper = ({
  open,
  anchorEl,
  placement,
  setOpen,
  roiFormData,
}) => {
  const [name, setName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const sendDiscordNotification = async (formData) => {
    try {
      const payload = {
        content: `New prospect\nName: ${formData.name}\nPlant size: ${formData.plantSize || '12'}kw`,
        username: 'Support bot',
        embeds: [
          {
            url: 'https://www.vaysolar.com/en/roi',
            color: 3447003,
            title: 'Details',
            fields: [
              {
                name: 'Plant size',
                value: `${formData.plantSize || '12'} KW`,
                inline: true,
              },
              {
                name: 'Name',
                value: formData.name,
                inline: true,
              },
              {
                name: 'Phone',
                value: formData.phone,
                inline: true,
              },
              {
                name: 'Type',
                value: formData.type || 'Commercial',
                inline: true,
              },
              {
                name: 'Max bill',
                value: formData.maxBill || '4000',
                inline: true,
              },
              {
                name: 'Min bill',
                value: formData.minBill || '2000',
                inline: true,
              },
            ],
            description: 'Appointment requested',
          },
        ],
      };

      const response = await fetch(DISCORD_WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Discord webhook failed: ${response.statusText}`);
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!name.trim() || !contactNumber.trim()) {
      alert('Please provide your name and contact number');
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = {
        name: name,
        phone: contactNumber,
        plantSize: roiFormData?.customPlantSize || '12',
        type: roiFormData?.propertyType || 'Commercial',
        maxBill: roiFormData?.maxBill || '4000',
        minBill: roiFormData?.minBill || '2000',
      };

      await sendDiscordNotification(formData);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setSubmitSuccess(true);

      setTimeout(() => {
        setName('');
        setContactNumber('');
        setSubmitSuccess(false);
        setOpen(false);
      }, 2000);
    } catch (error) {
      console.error('Error processing appointment request:', error);
      alert('Failed to submit appointment request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Popper
      sx={{ zIndex: 1200 }}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={2}>
            <Card
              sx={{
                padding: 4,
                borderRadius: 3,
                boxShadow: 3,
                position: 'relative',
              }}
            >
              {submitSuccess ? (
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 180,
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      fontSize: 60,
                      color: theme.palette.success.main,
                      mb: 2,
                    }}
                  />
                  <Typography variant='h6' align='center' fontWeight='bold'>
                    Appointment Requested!
                  </Typography>
                  <Typography variant='body1' align='center' sx={{ mt: 2 }}>
                    Thank you {name}, we'll contact you shortly.
                  </Typography>
                </CardContent>
              ) : (
                <>
                  <CardContent
                    sx={{
                      paddingRight: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='h6' fontWeight='bold' pb={2}>
                      Appointment request
                    </Typography>
                    <TextField
                      label='Name'
                      variant='standard'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      helperText='your full name'
                    />
                    <TextField
                      sx={{ mt: 1 }}
                      label='Contact number'
                      variant='standard'
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                      helperText='for us to reach you'
                    />
                  </CardContent>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    p={2}
                  >
                    <IconButton onClick={() => setOpen(false)}>
                      <CancelIcon />
                    </IconButton>
                    <IconButton disabled={isSubmitting} onClick={handleSubmit}>
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        <CheckCircleIcon
                          sx={{ color: theme.palette.secondary.light }}
                        />
                      )}
                    </IconButton>
                    <Button
                      size='small'
                      variant='caption'
                      fontWeight={'bold'}
                      mt={1.5}
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {'Book Free Consultation'}
                    </Button>
                  </Box>
                </>
              )}
            </Card>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default AppointmentRequestPopper;
