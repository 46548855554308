import { Check, Person, WorkspacePremium } from '@mui/icons-material';
import FeedIcon from '@mui/icons-material/Feed';
import GppGoodIcon from '@mui/icons-material/GppGood';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import vendor_placeholder from '../../Image/vendor_placeholder.png';
import {
  createMatch,
  requestQuote,
  requestQuoteTypeSMS,
  updateIsConvertedStatus,
} from '../../services/CustomerService.js';
import { isLoggedIn } from '../../utils/utils.js';
import { isEmpty } from '../constant.js';
import SiteVisitDialog from '../dashboard/SiteVisitDialog.js';
import LoginMiniForm from '../ui/LoginMiniForm.js';
import VendorSummary from './VendorSummary.js';

export default function VendorHeader({
  vendor,
  customerId,
  siteVisitData,
  propertyId,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [quoteStatus, setQuoteStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoginFormDialogOpen, setIsLoginFormDialogOpen] = useState(false);

  // const callPhoneNumber = async () => {
  //   if (isEmpty(siteVisitData)) {
  //     setOpen(true);
  //     return;
  //   }
  //   await customerInitiatesCallEvent(customerId, vendor?.id);
  //   window.open(`tel:+${vendor?.phoneNumber}`);
  //   trackGoogleAnalyticsEvent(
  //     INTERACTION,
  //     Interaction.CUSTOMER_INITIATED_CALL,
  //     {
  //       source: getUserDetails().id,
  //       target: vendor?.id,
  //       createdAt: new Date(),
  //     },
  //   );
  // };

  const openVendorProfile = () => {
    const vendorNameEncoded = vendor?.establishmentName.replace(/\s+/g, '-');
    const vendorInfo = `${vendorNameEncoded}_${vendor?.id}`;
    navigate(`/vendor-profile/${vendorInfo}`);
  };

  const handleQuote = async (vendorId = null, customerId = null) => {
    if (!isEmpty(siteVisitData)) {
      const res = await requestQuote(customerId, vendorId);
      const res2 = await requestQuoteTypeSMS(customerId, vendorId);
      await createMatch(customerId, vendorId);
      await updateIsConvertedStatus(vendor?.recommendationId, true);
      if (res || res2) {
        setQuoteStatus(true);
        toast.success('Quote requested successfully');
      }
      return;
    }
    setOpen(true);
  };

  const handleNext = () => {
    navigate(`/site-details?id=${propertyId}&step=1`);
  };

  const closeLoginForm = (didUserLogin = false) => {
    setIsLoginFormDialogOpen(false);
    if (didUserLogin) navigate('/dashboard');
  };

  const handleRequestCall = async () => {
    setIsLoginFormDialogOpen(true);
  };

  return (
    <>
      <Accordion
        defaultExpanded
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          paddingInline: { sm: '20px', xs: '2px' },
          borderRadius: '9px !important',
          boxShadow: 'none',
          border: '1px solid lightGray',
          '.MuiAccordionSummary-root': {
            alignItems: 'start',
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            margin: '13px 0',
            marginLeft: '4px',
          },
        }}
      >
        <AccordionSummary>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            width='100%'
          >
            <Stack gap={'2px'}>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Typography
                  variant='h6'
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {vendor?.establishmentName}
                </Typography>
                {vendor?.verificationStatus === 'VERIFIED' && (
                  <GppGoodIcon
                    sx={{
                      color: 'primary.main',
                      fontSize: { sm: '34px', xs: '32px' },
                    }}
                  />
                )}
              </Stack>
              <Stack flexDirection={'row'}>
                <Stack
                  display={isMobile ? 'flex' : 'none'}
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent='start'
                >
                  {vendor?.reputation > 100 ? (
                    <Stack
                      direction={'row'}
                      alignItems={'flex-start'}
                      gap={0.5}
                    >
                      <Typography
                        color={'primary'}
                        variant='body2'
                        fontWeight={'bold'}
                      >
                        Highly reputed
                      </Typography>
                      <WorkspacePremium
                        sx={{
                          fontSize: '22px',
                          color: theme.palette.primary.main,
                        }}
                      />
                    </Stack>
                  ) : vendor?.reputation > 70 && vendor?.reputation <= 100 ? (
                    <Stack direction={'row'} alignItems={'flex-start'} gap={1}>
                      <Typography color={'primary'} variant='body2'>
                        Reputed
                      </Typography>
                      <WorkspacePremium
                        sx={{
                          fontSize: '22px',
                          color: 'gray',
                        }}
                      />
                    </Stack>
                  ) : null}
                </Stack>
                {/* <IconButton
                  style={{ padding: 0 }}
                  href='https://docs.google.com/document/d/1GcbSnaIykmXI41y8yK02x-HKv5RP7DEz3n3OA_OW1o0/edit?usp=sharing'
                  target='_blank'
                >
                  {isMobile && (
                    <HelpOutline
                      sx={{
                        fontSize: '22px',
                        color: theme.palette.primary.main,
                      }}
                    />
                  )}
                </IconButton> */}
              </Stack>
              <Stack direction={'column'} flex={1}>
                <Typography
                  fontWeight='500'
                  variant='caption'
                  component={'p'}
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  {vendor?.address?.street1 && `${vendor.address.street1}, `}
                  {vendor?.address?.street2 && `${vendor.address.street2}`}
                  {vendor?.address?.state && ` ${vendor.address.state}`}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              display={isMobile ? 'none' : 'flex'}
              direction={'column'}
              alignItems={'center'}
              justifyContent='flex-end'
            >
              {vendor?.reputation > 100 ? (
                <Stack direction={'column'} alignItems={'flex-end'} gap={0.5}>
                  <WorkspacePremium
                    sx={{
                      fontSize: '42px',
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography variant='caption' fontWeight={'bold'}>
                    Highly reputed
                  </Typography>
                </Stack>
              ) : vendor?.reputation > 70 && vendor?.reputation <= 100 ? (
                <Stack direction={'column'} alignItems={'center'} gap={1}>
                  <WorkspacePremium
                    sx={{
                      fontSize: '32px',
                      color: 'gray',
                    }}
                  />
                  <Typography variant='caption'>Reputed</Typography>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        </AccordionSummary>
        <Stack
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          padding={'0px 16px'}
        >
          <Box>
            {vendor?.suryagharEligible && (
              <Chip
                label='PM Suryaghar Empanelled'
                size={isMobile ? 'small' : ''}
                sx={{
                  width: 'fit-content',
                  p: '4px',
                  bgcolor: theme.palette.secondary.lighter,
                }}
              />
            )}
          </Box>
          {vendor?.reputation > 70 && (
            <Link
              href='https://docs.google.com/document/d/1GcbSnaIykmXI41y8yK02x-HKv5RP7DEz3n3OA_OW1o0/edit?usp=sharing'
              target='_blank'
            >
              {!isMobile && (
                <Typography variant='caption'>How reputation works?</Typography>
              )}
            </Link>
          )}
        </Stack>
        <AccordionDetails>
          <Box>
            <Stack
              direction={'row'}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
            >
              <Box>
                <VendorSummary vendor={vendor} key={vendor.id} />
              </Box>
              {vendor.logoUrl && (
                <Box
                  component='img'
                  src={vendor?.logoUrl ? vendor?.logoUrl : vendor_placeholder}
                  alt='vendor logo'
                  sx={{
                    height: { xs: '96px', md: '120px' },
                    display: { xs: 'none', sm: 'flex' },
                  }}
                />
              )}
            </Stack>
            <Stack
              direction={'row'}
              gap={{ sm: 2, xs: 1.5 }}
              mt={2}
              justifyContent={'flex-end'}
              flexWrap={{ sm: 'nowrap', xs: 'wrap-reverse' }}
              ml={'auto'}
              maxWidth={550}
            >
              <Stack direction={'row'} gap={1}>
                <Button
                  variant='outlined'
                  disableElevation
                  startIcon={<Person />}
                  onClick={openVendorProfile}
                  size={isMobile ? 'small' : 'default'}
                >
                  View Profile
                </Button>
                <Button
                  variant='contained'
                  disableElevation
                  onClick={() => {
                    if (isLoggedIn()) {
                      handleQuote(vendor.id, customerId);
                    } else {
                      handleRequestCall();
                    }
                  }}
                  startIcon={quoteStatus ? <Check /> : <FeedIcon />}
                  disabled={quoteStatus || vendor?.isConverted}
                  size={isMobile ? 'small' : 'default'}
                >
                  {quoteStatus ? 'Request sent' : 'Request Quote'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
      <SiteVisitDialog open={open} setOpen={setOpen} handleNext={handleNext} />
      <LoginMiniForm
        open={isLoginFormDialogOpen}
        handleClose={closeLoginForm}
        vendorId={vendor?.id}
      />
      <Toaster position='bottom-center' />
    </>
  );
}
