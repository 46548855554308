import { WorkspacePremium } from '@mui/icons-material';
import GppGoodIcon from '@mui/icons-material/GppGood';
import {
  Chip,
  Link,
  ListItem,
  Stack,
  useMediaQuery
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import vendor_placeholder from '../../Image/vendor_placeholder.png';

export default function VendorProfile({ vendor }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <ListItem
        alignItems='flex-start'
        sx={{
          padding: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '20px',
        }}
      >
        <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
          <Stack
            gap={3}
            direction={'row'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
          >
            {vendor?.logoUrl ? (
              <Box
                display='flex'
                flexDirection='column'
                sx={{
                  padding: { sm: '5px 0px', xs: '4px 0px' },
                  color: 'primary.main',
                  alignItems: 'flex-start',
                }}
              >
                <Stack alignItems={'center'}>
                  {vendor?.logoUrl ? (
                    <Box
                      display='flex'
                      flexDirection='column'
                      gap='25px'
                      padding={'unset'}
                    >
                      <Box
                        component='img'
                        src={
                          vendor?.logoUrl
                            ? vendor?.logoUrl
                            : vendor_placeholder
                        }
                        alt='Vendor Logo'
                        sx={{
                          height: { xs: '96px', md: '120px' },
                          display: 'flex',
                        }}
                        borderRadius='10px'
                        border={'1px solid #E0E0E0'}
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Box>
            ) : (
              <></>
            )}
            <Stack
              direction={'column'}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
            >
              <Typography
                maxWidth={550}
                fontWeight='bold'
                fontSize={{ xs: '1.5rem', md: '3rem' }}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
                gap={1}
              >
                {vendor?.establishmentName}
                <Box paddingTop={0.5}>
                  {vendor?.verificationStatus === 'VERIFIED' && (
                    <GppGoodIcon
                      sx={{
                        color: 'primary.main',
                        fontSize: { xs: '2rem', md: '3rem' },
                      }}
                    />
                  )}
                </Box>
              </Typography>
              {isMobile && vendor?.reputation > 70 && (
                <Stack >
                  <Stack direction={'row'} alignItems={'center'}>
                    <Typography variant='body1'>
                      {vendor?.reputation > 100
                        ? 'Highly reputed'
                        : vendor?.reputation > 70
                          ? 'Reputed'
                          : null}
                    </Typography>
                    <WorkspacePremium
                      sx={{
                        color: vendor?.reputation > 70 ? 'gray' : theme.palette.secondary.main,
                      }}
                    />
                  </Stack>
                </Stack>
              )}
              <Typography
                fontWeight='500'
                sx={{
                  fontSize: {
                    xs: theme.typography.body2.fontSize,
                    md: theme.typography.body1.fontSize,
                  },
                  color: 'text.secondary',
                }}
              >
                {vendor?.address?.locality && (
                  <>
                    {vendor.address.locality}
                    <br />
                  </>
                )}
                {vendor?.address?.district &&
                  `${vendor.address.district}, `}
                {vendor?.address?.state && `${vendor.address.state}`}
                {vendor?.address?.pinCode && ` - ${vendor.address.pinCode}`}
              </Typography>
              {vendor?.suryagharEligible && (
                <Chip
                  label='PM Suryaghar Empanelled'
                  size={isMobile ? 'small' : ''}
                  sx={{
                    mt: '12px',
                    width: 'fit-content',
                    p: '4px',
                    bgcolor: theme.palette.secondary.lighter,
                  }}
                />
              )}
            </Stack>
          </Stack>
          {!isMobile && vendor?.reputation > 70 && (
            <Stack paddingTop={'16px'} alignItems={'flex-end'}>
              <Stack direction={'row'} alignItems={'center'}>
                <WorkspacePremium
                  sx={{
                    fontSize: { xs: '48px', md: '48px' },
                    color: vendor?.reputation > 70 ? 'gray' : theme.palette.secondary.main,
                  }}
                />
                <Typography variant='h5'>
                  {vendor?.reputation > 100
                    ? 'Highly reputed'
                    : vendor?.reputation > 70
                      ? 'Reputed'
                      : null}
                </Typography>
              </Stack>
              <Link
                mt={1}
                href='https://docs.google.com/document/d/1GcbSnaIykmXI41y8yK02x-HKv5RP7DEz3n3OA_OW1o0/edit?usp=sharing'
                target='_blank'
              >
                How reputation works?
              </Link>
            </Stack>
          )}
        </Box>
      </ListItem>
      {/* <VendorProfileStatistics vendor={vendor} /> */}
      {/* <VendorSummary vendor={vendor} /> */}
    </Box>
  );
}
